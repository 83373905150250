import { Components, Theme } from '@mui/material'

import { colors } from '../colors'

// Add your own variants to this module override
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inverted: true
  }
}

const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      '& .icon-button': {
        marginLeft: '15px',
      },
      borderRadius: '16px',
      fontSize: '0.75rem',
      fontWeight: 700,
      height: ownerState.size === 'small' ? '42px' : '64px',
      textTransform: 'uppercase',
    }),
  },
  variants: [
    {
      props: {
        variant: 'inverted',
      },
      style: {},
    },
    {
      props: {
        variant: 'contained',
      },
      style: ({ theme }) => ({
        '& .icon-button': {
          backgroundColor: colors.WHITE,
          border: ` 1px solid ${theme.palette.primary.main}`,
          borderRadius: '50%',
          boxSizing: 'content-box',
          color: theme.palette.primary.main,
        },
        '&.disabled': {
          '& .icon-button': {
            backgroundColor: colors.GREY_LIGHT,
            border: ` 1px solid ${colors.WHITE}`,
            color: colors.WHITE,
          },
          backgroundColor: colors.WHITE,
          boxShadow: 'none',
          color: colors.GREY_LIGHT,
          cursor: 'default',
        },
        backgroundColor: theme.palette.primary.main,
        color: colors.WHITE,
      }),
    },
  ],
}

const MuiButtonBase: Components['MuiButtonBase'] = {
  styleOverrides: {
    disabled: {},
    root: {
      padding: '20px',
    },
  },
}

export const ButtonTheme = {
  MuiButton,
  MuiButtonBase,
}
