import { Components, Theme } from '@mui/material'

import { colors } from '../colors'
import { clampBuilder } from '../scaling'
import Shadows from '../shadows'

// Add your own variants to this module override
declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    dashed: true
  }
}

const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '.Mui-error': {
        color: colors.RED,
      },
      '.MuiButtonBase-root': {
        color: 'inherit',
      },
      '.MuiInput-underline': {
        '&.MuiError': {
          '&:after': {
            borderBottom: `2px solid ${colors.RED}`,
          },
          '&:before': {
            borderColor: colors.RED,
          },
          '&:hover:not(.MuiDisabled):before': {
            borderBottom: `1px solid ${colors.RED}`,
          },
        },
        '&:after:not(.Mui-error)': {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
          borderColor: theme.palette.primary.main,
        },
        '&:hover:not(.MuiDisabled):before': {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '.MuiInputAdornment-root': {
        '&.errorAdornment': {
          color: colors.RED,
        },
        '&.validAdornment': {
          color: theme.palette.primary.main,
        },
        flex: '0 0 auto',
        fontSize: clampBuilder(1200, 1800, 2, 2.8),
        height: '100%',
        justifyContent: 'center',
        maxHeight: 'none',
        width: clampBuilder(1200, 1800, 2, 2.8),
      },
      '.MuiInputBase-input': {
        [theme.breakpoints.up('xl')]: {
          height: '56px',
          paddingTop: '10px',
        },
        [theme.breakpoints.up('md')]: {
          paddingTop: '10px',
        },
        height: '30px',
        letterSpacing: 'normal',
        marginTop: 'auto',
        paddingBottom: '10px',
        paddingLeft: '0px',
        paddingTop: '0px',
        textAlign: 'left',
      },
      '.MuiInputBase-root': {
        fontSize: clampBuilder(1200, 1800, 1.5, 2.5),
        fontWeight: 'bold',
        lineHeight: clampBuilder(1200, 1800, 1.6, 2.5),
        marginBottom: 0,
        marginTop: 'auto',
      },
      '.MuiInputLabel-root': {
        '&.Mui-error': {
          color: colors.RED,
        },
        [theme.breakpoints.up('md')]: {
          height: '44px',
        },
        borderBottom: '0.5px',
        color: theme.palette.primary.main,
        display: 'block',
        fontSize: clampBuilder(1200, 1800, 1.5, 2.5),
        fontWeight: 'bold',
        height: '30px',
        lineHeight: clampBuilder(1200, 1800, 1.6, 2.5),
        maxWidth: '100%',
        overflow: 'hidden',
        transform: 'translate(0, 5px)',
        whiteSpace: 'nowrap',
      },
      '.MuiInputLabel-shrink': {
        '&.Mui-error': {
          color: colors.RED,
        },
        color: theme.palette.primary.main,
        fontSize: clampBuilder(1200, 1800, 0.8, 1.5),
        fontWeight: 'bold',
        lineHeight: clampBuilder(1200, 1800, 1, 1.6),
        transform: 'translate(0, -30px)',
        transformOrigin: 'top left',
      },
    }),
  },
  variants: [
    {
      props: {
        variant: 'outlined',
      },
      style: ({ theme }) => ({
        '.MuiInputBase-root': {
          '&:hover': {
            boxShadow: Shadows.Shadow1,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          backgroundColor: colors.WHITE,
          border: `2px dashed ${colors.GREY_PRIMARY}`,
          borderRadius: '20px',
          color: colors.BLACK,
        },
        '.MuiInputLabel-root': {
          height: '40px',
          paddingLeft: '20px',
          paddingTop: '10px',
          [theme.breakpoints.up('md')]: {
            height: '54px',
          },
        },
        '.MuiInputLabel-shrink': {
          transform: 'translate(0px, -50px)',
        },
      }),
    },
  ],
}

export const TextFieldTheme = {
  MuiTextField,
}
