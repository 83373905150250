import { Box } from '@mui/material'
import List from '@mui/material/List'
import { FC } from 'react'

import { FileItem } from './FileItem'
import { FileOC } from '../interfaces/api'
import { colors } from '../theming/colors'

export const AttachmentViewer: FC<{
  files: FileOC[] | null
  onClick: (file: FileOC) => void
}> = ({ files, onClick }) => {
  function generateItems() {
    if (files && files.length > 0) {
      return files.map((file: FileOC) => {
        return (
          <FileItem
            key={file.FileName}
            file={file}
            onClick={() => onClick(file)}
          />
        )
      })
    } else {
      return null
    }
  }

  const items = generateItems()

  return (
    <Box
      sx={{
        justifyContent: 'center',
        maxWidth: { lg: 550 },
        width: '100%',
      }}
    >
      {items && (
        <List
          sx={{
            backgroundColor: colors.WHITE,
            color: colors.GREY_DARK,
            borderRadius: '8px',
            overflow: 'auto',
          }}
        >
          {items}
        </List>
      )}
    </Box>
  )
}
