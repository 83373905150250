export const Shadows = {
  Shadow1: `0px 2px 1px -1px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.21), 0px 1px 3px 0px rgba(0, 0, 0, 0.18)`,
  Shadow12: `0px 7px 8px -4px rgba(0, 0, 0, 0.3), 0px 12px 17px 2px rgba(0, 0, 0, 0.21), 0px 5px 22px 4px rgba(0, 0, 0, 0.18)`,
  Shadow2: `0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 2px 2px 0px rgba(0, 0, 0, 0.21), 0px 1px 5px 0px rgba(0, 0, 0, 0.18)`,
  Shadow3: `0px 3px 3px -2px rgba(0, 0, 0, 0.3), 0px 3px 4px 0px rgba(0, 0, 0, 0.21), 0px 1px 8px 0px rgba(0, 0, 0, 0.18)`,
  Shadow4: `0px 2px 4px -1px rgba(0, 0, 0, 0.3), 0px 4px 5px 0px rgba(0, 0, 0, 0.21), 0px 1px 10px 0px rgba(0, 0, 0, 0.18)`,
  Shadow6: `0px 3px 5px -1px rgba(0, 0, 0, 0.3), 0px 6px 10px 0px rgba(0, 0, 0, 0.21), 0px 1px 18px 0px rgba(0, 0, 0, 0.18)`,
}

export default Shadows
