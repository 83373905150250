import { intlInstance } from '../../hooks/useLocalization'

export const createLabelFn =
  <
    T extends Record<
      string,
      { defaultMessage: string; description?: string; id: string }
    >,
  >(
    labelMap: T,
    defaultMessage?: { defaultMessage: string; id: string }
  ) =>
  (labelId: string) => {
    const idExists = !!intlInstance?.messages[labelId]

    const label = labelMap[labelId]

    if (!label && idExists) {
      return {
        defaultMessage: labelId,
        id: labelId,
      }
    }
    if (!label && !idExists) {
      console.warn(`No translation found for id: ${labelId}`)
      return (
        defaultMessage || {
          defaultMessage: labelId,
          id: 'UNKNOWN',
        }
      )
    }

    return label
  }
