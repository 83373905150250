import {
  Box,
  CssBaseline,
  Theme as ThemeProps,
  ThemeProvider,
} from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'

import { useBranding } from '../hooks/useBranding'
import { generateTheme } from '../theming/generateTheme'
import { useLocalization } from '../hooks/useLocalization'

export const Theme: FC<PropsWithChildren<{ theme?: ThemeProps }>> = ({
  children,
  theme,
}) => {
  const { branding } = useBranding()
  const { locale } = useLocalization()

  const favicon = branding?.Favicon
    ? 'data:image/png;base64,' + branding?.Favicon
    : '/favicon.png'

  return (
    <ThemeProvider theme={theme ?? generateTheme(branding)}>
      <CssBaseline />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="auto"
      >
        <Helmet htmlAttributes={{ lang: locale }}>
          <title>{branding?.Name}</title>
          <link href={favicon} id="favicon" rel="icon" type="image/png" />
        </Helmet>
        {children}
      </Box>
    </ThemeProvider>
  )
}
