import { LogoSizeOptions } from '../interfaces/api'

export const chooseLogoSize = (size: LogoSizeOptions) => {
  switch (size) {
    case 'XS':
      return 150
    case 'S':
      return 175
    case 'M':
      return 200
    case 'L':
      return 225
    case 'XL':
      return 250
    default:
      return 200
  }
}

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minFontSize: number,
  maxFontSize: number
) => {
  const root = document.querySelector('html') as HTMLElement
  const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2))

  const minWidth = minWidthPx / pixelsPerRem
  const maxWidth = maxWidthPx / pixelsPerRem

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minFontSize

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`
}
