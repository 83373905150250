import { ThemeContext } from '@emotion/react'
import { styled } from '@mui/material'
import { Context, useContext } from 'react'

const StyledImg = styled('img')(({ theme }) => ({
  height: 'auto',
  margin: 0,
  [theme.breakpoints.up('xs')]: {
    width: `${theme.logo.logoSize - 50}px`,
  },
  [theme.breakpoints.up('md')]: {
    width: `${theme.logo.logoSize}px`,
  },
  [theme.breakpoints.up('xl')]: {
    width: `${theme.logo.logoSize + 50}px`,
  },
}))

export function Logo() {
  const themeContext = useContext(
    ThemeContext as Context<{ logo: { url: string } }>
  )
  return <StyledImg src={themeContext.logo.url} />
}
