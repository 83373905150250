import Color from 'color'
import { createTheme } from '@mui/material'

import { BrandingResponse } from '../interfaces/api'
import { colors, isLight } from './colors'

import { ButtonTheme } from './components/button.theme'
import { TextFieldTheme } from './components/textfield.theme'

import { chooseLogoSize } from './scaling'

export const generateTheme = (
  branding: BrandingResponse | Partial<BrandingResponse>
) => {
  const muiTheme = {
    background: branding?.BackgroundURL ?? '',
    breakpoints: {
      values: {
        lg: 1028,
        md: 900,
        sm: 600,
        xl: 1920,
        xs: 0,
      },
    },

    favicon: branding?.Favicon ?? '',
    logo: {
      logoSize: branding?.LogoSize
        ? chooseLogoSize(branding.LogoSize)
        : chooseLogoSize('M'),
      url: branding?.LogoURL ?? '',
    },
    palette: {
      colors,
      primary: {
        contrastText: isLight(Color(branding?.ColorPrimaryHEX).hex())
          ? colors.GREY_DARK
          : colors.WHITE,
        main: branding?.ColorPrimaryHEX ?? colors.BLACK,
        light: Color(branding?.ColorPrimaryHEX ?? colors.BLACK)
          .rgb()
          .alpha(0.08)
          .string(),
      },
      secondary: {
        contrastText: isLight(
          Color(branding?.ColorSecondaryHEX ?? colors.GREY_LIGHT).hex()
        )
          ? colors.GREY_DARK
          : colors.WHITE,
        main: branding?.ColorSecondaryHEX ?? colors.GREY_LIGHT,
      },
    },
    typography: {
      fontFamily: branding.FontFamily ?? 'Gabriel Sans',
    },
  }
  const theme = createTheme(muiTheme)

  // Component overrides here, see: https://mui.com/customization/theme-components/
  theme.components = {
    ...ButtonTheme,
    ...TextFieldTheme,
  }
  return theme
}
