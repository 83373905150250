import { useMutation } from '@tanstack/react-query'

import { HttpError } from '../interfaces/api'
import { useHttpErrors } from './useHttpError'
import { useIdentifications } from './useIdentifications'
import { postSignedDocs } from '../api/postSignedDocs'
import { useEffect } from 'react'
import { useSignDocsQuery } from '../queries/useSignDocsQuery'

export const useSignDocs = () => {
  const { UUID, validationCode, userIntent } = useIdentifications()
  const { updateHttpError } = useHttpErrors()

  const signDocsQuery = useSignDocsQuery(UUID, validationCode, ['signableDocs'])

  const onError = (error: unknown) => {
    const errorContext = {
      error: error,
      uuid: UUID ?? '',
      name: 'error on useSignDocs - get docs to sign',
    }
    updateHttpError(error as HttpError, errorContext)
  }

  const fetchDocs = async () => {
    const fetchedFiles = await signDocsQuery.refetch()
    if (fetchedFiles.status === 'success') {
      return
    }
    if (fetchedFiles.status === 'error') {
      onError(fetchedFiles.error)
      return
    }
  }

  useEffect(() => {
    if (UUID && validationCode && userIntent === 'Sign') fetchDocs()
  }, [UUID, validationCode, userIntent])

  const signedDocMutation = useMutation({
    mutationKey: ['signedDocMutation'],

    mutationFn: async ({
      documentId,
      signedDate,
    }: {
      documentId: string
      signedDate: string
    }) => {
      const response = await postSignedDocs(
        { documentId, signedDate },
        UUID,
        validationCode
      )
      return response
    },

    onError: (error: unknown) => {
      const errorContext = {
        error: error,
        lp: '',
        name: 'error on useSignDocs - post docs signed',
        uuid: UUID ?? '',
      }
      updateHttpError(error as HttpError, errorContext)
    },
  })

  return {
    signableDocs: signDocsQuery.data ?? null,
    isFetchingSignableDocs: signDocsQuery.isFetching,
    hasSignableDocs: Boolean(
      signDocsQuery?.data && signDocsQuery?.data?.Signables?.length > 0
    ),
    confirmSignedDocument: async (documentId: string, signedDate: string) =>
      signedDocMutation.mutate({ documentId, signedDate }),
  }
}
