import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { ErrorContext, HttpError } from '../interfaces/api'

interface ContextData {
  clearHttpError: () => void
  errorContext: ErrorContext | null
  httpError: HttpError | null
  updateHttpError: (error: HttpError, errorContext: ErrorContext) => void
}

const noop = () => null

export const HttpErroContext = createContext<ContextData>({
  clearHttpError: noop,
  errorContext: null,
  httpError: null,
  updateHttpError: noop,
})

export const HttpErrorProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [httpError, setHtttpError] = useState<HttpError | null>(null)
  const [errorContext, setErrorContext] = useState<ErrorContext | null>(null)

  return (
    <HttpErroContext.Provider
      value={{
        clearHttpError: () => {
          setHtttpError(null)
          setErrorContext(null)
        },
        errorContext: errorContext,
        httpError: httpError,
        updateHttpError: (error, contextForError) => {
          setHtttpError(error)
          setErrorContext(contextForError)
        },
      }}
    >
      {children}
    </HttpErroContext.Provider>
  )
}

export const useHttpErrors = () => {
  return useContext(HttpErroContext)
}
