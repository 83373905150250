import { keepPreviousData, QueryKey, useQuery } from '@tanstack/react-query'

import { SignDocsResponse } from '../interfaces/api'
import { getSignDocs } from '../api/getSignDocs'

export const useSignDocsQuery = (
  UUID: string | undefined,
  validationCode: string | undefined,
  queryKey: QueryKey
) => {
  return useQuery<SignDocsResponse>({
    queryKey: queryKey,
    queryFn: () => getSignDocs(UUID, validationCode),
    enabled: false,
    placeholderData: keepPreviousData,
    retry: 3,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
