import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  createIntl,
  createIntlCache,
  IntlProvider,
  IntlShape,
} from 'react-intl'

import { Loader } from '../components/Loader'
import { useBranding } from './useBranding'
import dayjs from 'dayjs'

import { loadLocale, TranslationMap } from '../translations'

export type SupportedLanguanges = 'en' | 'nl' | 'de'

const DEFAULT_LANGUAGE = 'en'

export let intlInstance: null | IntlShape = null

interface LocalizationData {
  locale: string
  updateLocale: (newLocale: SupportedLanguanges) => void
}

export const LocalizationContext = createContext<LocalizationData>({
  locale: 'en',
  updateLocale: () => {
    return
  },
})

export const LocalizationProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { availableLanguages, brandingID, usesFormalLanguage } = useBranding()

  const navigatorLanguage = navigator.language.split(
    /[-_]/
  )[0] as SupportedLanguanges
  const language = availableLanguages.includes(navigatorLanguage)
    ? navigatorLanguage
    : DEFAULT_LANGUAGE

  const [localization, setLocalization] = useState<{
    locale: string
    messages: TranslationMap | null
  }>({
    locale: language,
    messages: null,
  })

  // Initialize messages
  useEffect(() => {
    loadLocale(language, usesFormalLanguage, brandingID).then((messages) => {
      setLocalization({
        locale: language,
        messages,
      })
    })
  }, [brandingID])

  const selectLanguage = (newLocale: SupportedLanguanges) => {
    loadLocale(newLocale, usesFormalLanguage).then((messages) =>
      setLocalization({
        locale: newLocale,
        messages,
      })
    )
  }

  if (!localization.messages) {
    return <Loader fullscreen />
  }

  const cache = createIntlCache()
  intlInstance = createIntl(
    {
      locale: localization.locale,
      messages: localization.messages,
    },
    cache
  )

  const values = { locale: localization.locale, updateLocale: selectLanguage }

  dayjs.locale(localization.locale)
  return (
    <LocalizationContext.Provider value={values}>
      <IntlProvider
        locale={localization.locale}
        messages={localization.messages}
      >
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  )
}

export const useLocalization = () => {
  return useContext(LocalizationContext)
}
