import { BrandingResponse } from '../interfaces/api'
import { fetchAPI } from '../utils/fetchAPI'

export const getBranding = (brandingID: string | undefined) => {
  const API = process.env.REACT_APP_PUBLIC_API_URL

  const headers = {
    'Content-Type': 'application/json',
    ...(brandingID ? { Brandingid: brandingID } : {}),
  }

  return fetchAPI<BrandingResponse>(`${API}/customerportal/branding`, {
    headers: headers,
    method: 'GET',
  })
}
