import { datadogLogs } from '@datadog/browser-logs'

import { Box, Stack } from '@mui/material'
import { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { Title } from '../components/Title'
import { Paragraph } from '../components/Paragraph'

export const ErrorPage: FC = () => {
  const errorInstance = new Error('wrong url access')
  datadogLogs.logger.error(
    'wrong url access',
    {
      error: 'userIntent or uuid missing',
    },
    errorInstance
  )

  return (
    <Box
      component={'div'}
      sx={{ height: '100%', width: '100%', padding: '0 40px' }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          color: 'primary.main',
          height: '100%',
          justifyContent: 'center',
          '& form': {
            '& .MuiFormControl-root': {
              width: 'auto',
            },
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
            width: { md: '30%', xs: '100%' },
          },
        }}
      >
        <Title as="h3">
          <FormattedMessage
            defaultMessage="This is not a valid url"
            id="wrong_url_page.title"
          />
        </Title>
        <Paragraph>
          <FormattedMessage
            defaultMessage="Please, make sure the link you accessed is the same you received in your mail"
            id="wrong_url_page.text"
          />
        </Paragraph>
      </Stack>
    </Box>
  )
}
