import { Box, Stack } from '@mui/material'

import { Title } from '../components/Title'
import { FormattedMessage } from 'react-intl'
import { AttachmentViewer } from '../components/AttachmentViewer'
import { FileOC } from '../interfaces/api'
import { Button } from '../components/Button'
import { downloadFile } from '../utils/downloadFile'
import { PageProperties } from './getPageComponent'
import { FC } from 'react'

export const DownloadFiles: FC<PageProperties> = ({ files, hasFiles }) => {
  const availableFiles = files ? files.Attachments : null

  const onClick = (file: FileOC) => {
    downloadFile(file)
  }

  const onClickDownloadAll = async () => {
    if (availableFiles && availableFiles.length > 0) {
      await Promise.all(availableFiles.map(downloadFile))
    }
  }

  if (!hasFiles) {
    return (
      <Box component={'div'} sx={{ height: '100%' }}>
        <Stack
          spacing={3}
          sx={{
            alignItems: 'center',
            color: 'primary.main',
            height: '100%',
            justifyContent: 'center',
            '& form': {
              '& .MuiFormControl-root': {
                width: 'auto',
              },
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '10px',
              width: { lg: '50%', md: '30%', xs: '100%' },
            },
          }}
        >
          <Title as="h3">
            <FormattedMessage
              defaultMessage="There are no files available for download"
              description="download files text"
              id="download_files.no-files-title"
            />
          </Title>
        </Stack>
      </Box>
    )
  }
  return (
    <Box component={'div'} sx={{ height: '100%', padding: '0 40px' }}>
      <Stack
        spacing={3}
        sx={{
          alignItems: 'center',
          color: 'primary.main',
          height: '100%',
          justifyContent: 'center',
          '& form': {
            '& .MuiFormControl-root': {
              width: 'auto',
            },
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
            width: { lg: '50%', md: '30%', xs: '100%' },
          },
        }}
      >
        <Title as="h3">
          <FormattedMessage
            defaultMessage="These are the files available for download"
            description="download files text"
            id="download_files.title"
          />
        </Title>
        <Box component="div" sx={{ maxWidth: { lg: 550 }, width: '100%' }}>
          <AttachmentViewer files={availableFiles} onClick={onClick} />
        </Box>
        <Button
          sx={{ maxWidth: { lg: 550 }, width: '100%' }}
          onClick={onClickDownloadAll}
        >
          <FormattedMessage
            defaultMessage="Download all"
            description="fill validation code text"
            id="download_files.download-all-button"
          />
        </Button>
      </Stack>
    </Box>
  )
}
