import { keepPreviousData, QueryKey, useQuery } from '@tanstack/react-query'

import { getFiles } from '../api/getFiles'
import { FilesResponse } from '../interfaces/api'

export const useFilesQuery = (
  UUID: string | undefined,
  validationCode: string | undefined,
  queryKey: QueryKey
) => {
  return useQuery<FilesResponse>({
    queryKey: queryKey,
    queryFn: () => getFiles(UUID, validationCode),
    enabled: false,
    placeholderData: keepPreviousData,
    retry: 3,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
