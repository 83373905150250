import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState, MouseEvent, FC, Fragment } from 'react'

import { SupportedLanguanges, useLocalization } from '../hooks/useLocalization'

import { Button } from './Button'

interface LangMenuProps {
  availableLanguages: SupportedLanguanges[]
}
export const LangMenu: FC<LangMenuProps> = ({ availableLanguages }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (loc: SupportedLanguanges) => {
    setAnchorEl(null)
    updateLocale(loc)
  }

  const { locale, updateLocale } = useLocalization()

  const localeName = (local: string) => {
    switch (local) {
      case 'en':
        return 'EN'
      case 'nl':
        return 'NL'
      case 'de':
        return 'DE'
      default:
        return 'EN'
    }
  }

  return (
    <Fragment>
      <Button
        aria-controls="basic-menu"
        aria-expanded="true"
        aria-haspopup="true"
        id="basic-button"
        onClick={handleClick}
        sx={{
          borderRadius: '10px',
          height: '30px',
          minWidth: { md: '40px', xs: '20px' },
          padding: 0,
        }}
        variant="text"
      >
        {localeName(locale)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        id="basic-menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => handleClose(locale as SupportedLanguanges)}
        open={open}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        {availableLanguages.map((language) => {
          return (
            <MenuItem
              key={language}
              onClick={() => handleClose(language)}
              sx={{ color: 'primary.main' }}
            >
              {language.toUpperCase()}
            </MenuItem>
          )
        })}
      </Menu>
    </Fragment>
  )
}
