import { HttpError } from '../interfaces/api'
import { useHttpErrors } from './useHttpError'
import { useIdentifications } from './useIdentifications'
import { useEffect } from 'react'
import { useFilesQuery } from '../queries/useFilesQuery'

export const useFiles = () => {
  const { UUID, validationCode, userIntent } = useIdentifications()
  const { updateHttpError } = useHttpErrors()

  const filesQuery = useFilesQuery(UUID, validationCode, ['files'])

  const onError = (error: unknown) => {
    const errorContext = {
      error: error,
      uuid: UUID ?? '',
      name: 'error on useFiles - get files',
    }
    updateHttpError(error as HttpError, errorContext)
  }

  const fetchFiles = async () => {
    const fetchedFiles = await filesQuery.refetch()
    if (fetchedFiles.status === 'success') {
      return
    }
    if (fetchedFiles.status === 'error') {
      onError(fetchedFiles.error)
      return
    }
  }

  useEffect(() => {
    if (UUID && validationCode && userIntent === 'Download') {
      fetchFiles()
    }
  }, [UUID, validationCode, userIntent])

  return {
    files: filesQuery.data ?? null,
    isFetchingFiles: filesQuery.isFetching,
    hasFiles: Boolean(
      filesQuery?.data && filesQuery?.data?.Attachments?.length > 0
    ),
  }
}
