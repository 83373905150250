import { SignDocsResponse } from '../interfaces/api'
import { fetchAPI } from '../utils/fetchAPI'

export const getSignDocs = (UUID?: string, validationCode?: string) => {
  const API = process.env.REACT_APP_PUBLIC_API_URL

  const headers = {
    'Content-Type': 'application/json',
    ...(UUID ? { Uuid: UUID } : {}),
    ...(validationCode ? { 'Security-Code': validationCode } : {}),
  }

  return fetchAPI<SignDocsResponse>(`${API}/documentportal/v1/signables`, {
    headers,
    method: 'GET',
  })
}
