import Color from 'color'

export const colors = {
  BLACK: '#000000',
  BLUE: '#393EB9',
  GREY_DARK: '#434244',
  GREY_DARKER: '#212121',
  GREY_LIGHT: '#E2E2E2',
  GREY_LIGHTER: '#F2F2F2',
  GREY_PRIMARY: '#A1A0A1',
  RED: '#ed1c24',
  WHITE: '#FFFFFF',
  YELLOW: '#FFDD00',
}

export type Colors = typeof colors

export const isLight = (val: string) => {
  const baseColor = Color(val)
  const g = baseColor.green()
  const r = baseColor.red()
  const b = baseColor.blue()

  return r * 0.299 + g * 0.587 + b * 0.114 > 186
}
