import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { FC } from 'react'

import { Button } from './Button'

interface Props {
  buttonNo?: string
  buttonYes?: string
  onNoClick?: () => void
  onYesClick?: () => void
  open: boolean
  text: string
  title: string
}

export const AlertDialog: FC<Props> = ({
  buttonNo,
  buttonYes,
  onNoClick,
  onYesClick,
  open,
  text,
  title,
}) => {
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={open}
    >
      <Box sx={{ minWidth: { md: '500px' } }}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonNo && <Button onClick={onNoClick}>{buttonNo}</Button>}
          {buttonYes && (
            <Button
              onClick={onYesClick}
              sx={{
                padding: '10px 24px',
                width: { sm: '240px', xs: '100%' },
              }}
              autoFocus
            >
              {buttonYes}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
