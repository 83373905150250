import { SupportedLanguanges } from '../hooks/useLocalization'

type Unwrap<T> = T extends Promise<infer U> ? U : T
const TypeFn = () => import('./locales/en.json')
export type TranslationKeys = keyof Unwrap<ReturnType<typeof TypeFn>>
export type TranslationMap = Record<TranslationKeys, string>

// Dynamically import locale files (lazy-loaded)
const importModule = async (
  locale: SupportedLanguanges,
  prefersFormal?: boolean
): Promise<TranslationMap> => {
  const formalCode = `${locale}Formal`

  if (prefersFormal && Object.hasOwnProperty.call(localeMap, formalCode)) {
    locale = formalCode as SupportedLanguanges
  }
  const messages = (await import(`./locales/${locale}.json`)) as {
    default: TranslationMap
  }
  return messages.default
}

const localeMap = {
  en: './locales/en.json',
  nl: './locales/nl.json',
  de: './locales/de.json',
  nlFormal: './locales/nl_formal.json',
  deFormal: './locales/de_formal.json',
}

const getLocale = (
  locale: SupportedLanguanges | string,
  prefersFormal?: boolean
) => {
  switch (locale) {
    case 'en':
    case 'nl':
    case 'de':
      return importModule(locale, prefersFormal)
    default:
      return importModule('en')
  }
}

export const loadLocale = async (
  locale: SupportedLanguanges | string,
  prefersFormal?: boolean,
  brandingId?: string
): Promise<TranslationMap> => {
  const defaultLocale = await getLocale(locale, prefersFormal)
  return {
    ...defaultLocale,
  }
}
