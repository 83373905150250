import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Providers } from './Providers'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { setupWorker } from 'msw/browser'
import { handlers } from './mocks/handlers'
import { setupDevTools } from './mocks/setupDevTools'
import { weakStart } from './mocks/weakStart'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

datadogLogs.init({
  clientToken: process.env.REACT_APP_PUBLIC_DD_TOKEN ?? '',
  env: process.env.REACT_APP_PUBLIC_DD_ENV,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'Document Portal logs',
  site: 'datadoghq.com',
  useSecureSessionCookie: true,
})

datadogRum.init({
  applicationId: process.env.REACT_APP_PUBLIC_DD_APP_ID ?? '',
  clientToken: process.env.REACT_APP_PUBLIC_DD_TOKEN_RUM ?? '',
  env: process.env.REACT_APP_PUBLIC_DD_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  service: 'Document Portal RUM',
  site: 'datadoghq.com',
  trackLongTasks: true,
  trackResources: true,
  useSecureSessionCookie: true,
})

const worker = setupWorker(...handlers)

if (process.env.NODE_ENV === 'development') {
  setupDevTools()
  weakStart(worker)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Providers>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </Providers>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
