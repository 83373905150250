import { useState } from 'react'

import { browserStorage } from '../utils/browserStorage'

export const useCookieConsent = () => {
  const [preferences, setPreferences] = useState({ datadog: true })
  const [consent, setConsent] = useState<{
    datadog: boolean
  } | null>(browserStorage.CookieConsentStorage.get())

  return {
    consent,
    preferences,
    updateConsent: (consented: { datadog: boolean }) => {
      setConsent(consented)
      browserStorage.CookieConsentStorage.save(consented)
    },
    updatePreferences: (destinationId: 'datadog') =>
      setPreferences((currentState) => ({
        ...currentState,
        [destinationId]: !preferences[destinationId],
      })),
  }
}
