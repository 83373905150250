import { Box, BoxProps } from '@mui/material'
import { Children } from 'react'

import { clampBuilder } from '../theming/scaling'

type ParagraphProps = React.PropsWithChildren<{
  textAlign?: 'left' | 'center' | 'right'
}> &
  BoxProps

export function Paragraph({ children, ...props }: ParagraphProps) {
  return (
    <Box
      component="p"
      fontSize={clampBuilder(1200, 1800, 0.9, 1.0)}
      lineHeight={clampBuilder(1200, 1800, 1.0, 1.1)}
      margin="2px"
      {...(props.textAlign && { textAlign: props.textAlign })}
      {...props}
    >
      {Children.toArray(children)}
    </Box>
  )
}
