import { Box } from '@mui/system'
import { FC } from 'react'

import { colors } from '../theming/colors'

import { LangMenu } from './LangMenu'
import { Logo } from './Logo'
import { SupportedLanguanges } from '../hooks/useLocalization'

interface LangMenuProps {
  availableLanguages: SupportedLanguanges[]
}

export const Navbar: FC<LangMenuProps> = ({ availableLanguages }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottomColor: colors.GREY_LIGHT,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '40px 0',
        pointerEvents: 'none',
        width: '100%',
        zIndex: 10,
      }}
    >
      <Box height={'20px'} padding={'0px 40px'}>
        <Box minWidth={{ md: '40px', xs: '20px' }} />
      </Box>
      <Logo />
      <Box
        sx={{
          display: 'flex',
          padding: '0px 40px',
          pointerEvents: 'all',
        }}
      >
        <LangMenu availableLanguages={availableLanguages} />
      </Box>
    </Box>
  )
}
