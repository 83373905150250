import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Checkbox, Link, Stack, styled } from '@mui/material'
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'

import { Paragraph } from './Paragraph'
import { colors } from '../theming/colors'

import { Button } from './Button'

const CookieWrapper = styled(Stack)(({ theme }) => ({
  '& .MuiLink-root': {
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'black',
  },

  backgroundColor: colors.WHITE,
  borderRadius: '20px 20px 0 0',
  fontSize: '18px',
  margin: 'auto',
  width: 'calc(100% - 240px)',
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% - 160px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 60px)',
  },
}))

const BottomBlock = styled(Stack)(({ theme }) => ({
  '& .MuiButton-root': {
    height: '40px',
    marginTop: 'auto',
    width: '130px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiLink-root': {
    fontSize: '12px',
    marginTop: 'auto',
  },
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '20px',
  [theme.breakpoints.down('sm')]: {
    '& .MuiLink-root': {
      marginTop: '10px',
    },
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    backgroundColor: colors.WHITE,
    border: ` 1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    color: colors.WHITE,
    fontSize: 20,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: colors.WHITE,
      border: ` 1px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      fontSize: 20,
    },
    '&, & + .MuiFormControlLabel-label': {
      color: colors.GREY_PRIMARY,
    },
    backgroundColor: colors.WHITE,
  },
  backgroundColor: colors.WHITE,
  justifyContent: 'flex-end',
}))

type ids = 'datadog'

interface Destination<T> {
  category: string
  description: string
  id: T
  name: string
}

interface CookieInterface {
  consent: { datadog: boolean } | null
  preferences: { datadog: boolean }
  updateConsent: (consented: { datadog: boolean }) => void
  updatePreferences: (destinationId: ids) => void
}

export const Cookie: FC<CookieInterface> = ({
  consent,
  preferences,
  updateConsent,
}) => {
  const { formatMessage } = useIntl()
  const [editPreferences, setEditPreferences] = useState(false)

  const doNotDisplayCookie = process.env.REACT_APP_NO_COOKIE_FLAG
  const categoriesMandatoryConsent: Record<string, Destination<ids>[]> = {
    'Quality and Logs': [
      {
        category: 'Quality and Logs',
        description: formatMessage({
          defaultMessage:
            'Datadog is a monitoring and analytics tool for information technology and DevOps teams that can be used to determine performance metrics',
          id: 'cookie.datadog-description',
        }),
        id: 'datadog',
        name: 'Datadog',
      },
    ],
  }

  if (consent !== null || doNotDisplayCookie) {
    return <></>
  }

  return (
    <Box
      sx={{
        bottom: 0,
        maxHeight: '100vh',
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      }}
    >
      <CookieWrapper boxShadow={2} className="cookieBlock" padding={3}>
        {!editPreferences ? (
          <Stack>
            <Box>
              {formatMessage({
                defaultMessage: 'Cookie',
                description: 'Cookie Message',
                id: 'cookie.descriptions',
              })}
              <Link
                href="https://openclaims.com/uploads/Privacy-Statement-Openclaims.pdf"
                rel="noreferrer"
                target="_blank"
              >
                {formatMessage({
                  defaultMessage: 'Cookie-Policy',
                  description: 'Cookie Policy',
                  id: 'cookie.descriptions-link',
                })}
              </Link>
            </Box>
            <BottomBlock>
              <Button onClick={() => updateConsent({ datadog: true })}>
                ok
              </Button>
              <Link onClick={() => setEditPreferences(true)}>
                {formatMessage({
                  defaultMessage: 'Cookie-Policy',
                  description: 'Cookie Policy',
                  id: 'cookie.view-cookie-link',
                })}
              </Link>
            </BottomBlock>
          </Stack>
        ) : (
          <Box>
            {Object.keys(categoriesMandatoryConsent).map((category) => (
              <Box
                key={category}
                sx={{
                  marginBottom: 3,
                }}
              >
                <Box
                  sx={{
                    borderBottomColor: 'primary.main',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    fontWeight: 'bold',
                    marginBottom: 1.5,
                    paddingBottom: 2,
                  }}
                >
                  {category}
                </Box>
                {categoriesMandatoryConsent[category].map((destination) => (
                  <Box key={destination.id}>
                    <label>
                      <StyledCheckbox
                        checked={true}
                        checkedIcon={<CheckIcon />}
                        icon={<CircleIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled
                        sx={{
                          '& .MuiSvgIcon-root': {
                            border: ` 1px solid ${colors.GREY_LIGHT}`,
                            color: colors.GREY_LIGHT,
                          },
                          '&.Mui-checked': {
                            '& .MuiSvgIcon-root': {
                              border: ` 1px solid ${colors.GREY_LIGHT}`,
                              color: colors.GREY_LIGHT,
                            },
                          },
                        }}
                      />
                      {destination.name}
                      <Paragraph>{destination.description}</Paragraph>
                    </label>
                  </Box>
                ))}
              </Box>
            ))}
            <Stack direction="row" justifyContent="flex-end" mt={2} spacing={2}>
              <Button
                onClick={() => updateConsent(preferences)}
                size="small"
                sx={{ marginRight: 'auto' }}
                type="button"
              >
                {formatMessage({
                  defaultMessage: 'Save',
                  id: 'cookie.save-preferences',
                })}
              </Button>
            </Stack>
          </Box>
        )}
      </CookieWrapper>
    </Box>
  )
}
